<template>
  <section id="dashboard-home">
    <b-row class="match-height">

      <b-col
        lg="4"
      >
        <b-row class="match-height">
          <b-col lg="6" md="4">
            <home-order-chart :data="cash" />
          </b-col>
          <b-col lg="6" md="4">
            <home-profit-chart :data="cashless" />
          </b-col>
          <b-col md="6" lg="12" v-if="statistics.cards"> 
            <home-earnings-chart :data="{
              series:[
                statistics.cards.byTypeAccounts['1'],
                statistics.cards.byTypeAccounts['2']
              ]}" 
            />
          </b-col>
        </b-row>

      </b-col>

      <b-col lg="4" v-if="statistics.drivers">
        <home-drivers :data="statistics.drivers.byStatuses" />
      </b-col>

      <b-col
        lg="4"
        md="4"
        class="home-balances"
        v-if="statistics.balances"
      >
        <b-row class="match-height">
          <b-col
            xl="6"
            md="6"
            sm="6"
          >
            <statistic-card-vertical
              icon="DollarSignIcon"
              :statistic="parseFloat(statistics.balances.total[1]/100).toFixed(2) + ' грн'"
              statistic-title="Всього на готівкових рахунках"
              color="info"
            />
          </b-col>
          <b-col
            xl="6"
            md="6"
            sm="6"
          >
            <statistic-card-vertical
              color="warning"
              icon="CreditCardIcon"
              :statistic="parseFloat(statistics.balances.total[2]/100).toFixed(2) + ' грн'"
              statistic-title="Всього на безготівкових рахунках"
            />
          </b-col>
          <b-col
            xl="6"
            md="6"
            sm="6"
          >
            <statistic-card-vertical
              color="danger"
              icon="DollarSignIcon"
              :statistic="parseFloat(statistics.balances.totalDriversOnly[1]/100).toFixed(2) + ' грн'"
              statistic-title="Всього на готівкових рахунках водіїв"
            />
          </b-col>
          <b-col
            xl="6"
            md="6"
            sm="6"
          >
            <statistic-card-vertical
              color="primary"
              icon="CreditCardIcon"
              :statistic="parseFloat(statistics.balances.totalDriversOnly[2]/100).toFixed(2) + ' грн'"
              statistic-title="Всього на безготівкових рахунках водіїв"
            />
          </b-col>
        </b-row>
      </b-col>

    </b-row>

    <!-- <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          

        </b-row>
      </b-col>

      <b-col lg="8">
        <home-revenue-report :data="data.revenue" />
      </b-col>
    </b-row> -->

    <!-- <b-row class="match-height">
      <b-col lg="8">
        <home-company-table :table-data="data.companyTable" />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <home-meetup :data="data.meetup" />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <home-browser-states />
      </b-col>
    </b-row> -->

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getUserData } from '@/services/auth/utils'
import HomeMedal from './HomeMedal.vue'
import HomeStatistics from './HomeStatistics.vue'
import HomeRevenueReport from './HomeRevenueReport.vue'
import HomeOrderChart from './HomeOrderChart.vue'
import HomeProfitChart from './HomeProfitChart.vue'
import HomeEarningsChart from './HomeEarningsChart.vue'
import HomeCompanyTable from './HomeCompanyTable.vue'
import HomeMeetup from './HomeMeetup.vue'
import HomeBrowserStates from './HomeBrowserStates.vue'
import HomeGoalOverview from './HomeGoalOverview.vue'
import HomeDrivers from './HomeDrivers.vue'
import HomeAnalyticBalances from './HomeAnalyticBalances.vue'
import axiosIns from "@axios";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BRow,
    BCol,
    HomeMedal,
    HomeStatistics,
    HomeRevenueReport,
    HomeOrderChart,
    HomeProfitChart,
    HomeEarningsChart,
    HomeCompanyTable,
    HomeMeetup,
    HomeBrowserStates,
    HomeGoalOverview,
    HomeDrivers,
    HomeAnalyticBalances,
    StatisticCardVertical,
  },
  data() {
    return {
      data: {},
      cash: '',
      cashless: '',
      statistics: {}
    }
  },
  methods: {
    async getBalances() {

      const response = await axiosIns
        .get('/business/company/balances')
        .then(response => {
          this.cash = (response.data.wallet.filter(balance => balance.cashType == 1)[0].balance / 100).toFixed(2)
          this.cashless = (response.data.wallet.filter(balance => balance.cashType == 2)[0].balance / 100).toFixed(2)
        })
        .catch(() => {
          this.toast({
              component: ToastificationContent,
              props: {
                  title: 'Error fetching users list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
          })

          return false;
        })
    },
    async getDashboard() {

      const response = await axiosIns
        .get('/business/dashboard/common')
        .then(response => {
          this.statistics = response.data.statistics
        })
        .catch(() => {
          this.toast({
              component: ToastificationContent,
              props: {
                  title: 'Помилка при отриманні аналітики',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
          })
          return false
        })

      this.statistics.drivers.byStatuses = this.statistics.drivers.byStatuses.filter(driver => {
        return driver.count !== 0
      })
    },
  },
  setup() {
    
    const toast = useToast()

    return {
      toast
    }
  },
  created() {
    this.getBalances()
    this.getDashboard()

    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        const userData = getUserData()
        this.data.congratulations.name = userData.fullName
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.home-balances .card {
  height: 180px;
}

.home-balances .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-balances h2 {
  font-size: 16px;
}

.home-balances span {
  font-size: 12px;
}

</style>
